<template>
  <v-container>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account" :title="$t(title)">
          <v-card>
            <v-card-text>
              <customers-form
                :title="$t('new', { name: $tc('customer') })"
                :customer="customer"
                @validated="saveCustomer()"
              ></customers-form>
            </v-card-text>
          </v-card>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import constants from "@/constants";
export default {
  name: "CustomerNew",
  components: { CustomersForm: () => import("@/components/customer/Form") },
  data() {
    return {
      customer: {
        user: { role_id: constants.roles.CUSTOMER },
      },
    };
  },
  methods: {
    ...mapActions("customers", ["addCustomer"]),
    saveCustomer() {
      this.addCustomer({ customer: this.customer }).then((response) => {
        if (response) {
          this.errors.clear();
          this.$alert(this.$t("customer_create_success"), "", "");
        }
        this.$router.back();
      });
    },
  },
};
</script>